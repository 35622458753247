export function setByKey(key, value, ttl) {
  if (!key) {
    return
  }

  const expires = ttl ? new Date().getTime() + ttl * 1000 : null
  const item = {
    value: value,
    expires: expires,
  }

  localStorage.setItem(key, JSON.stringify(item))
  console.log(`localstorage key ${key} been set.`)
}

export function retrieveByKey(key) {
  if (!key) {
    return null
  }

  const itemStr = localStorage.getItem(key)

  if (!itemStr) {
    return null
  }

  try {
    const item = JSON.parse(itemStr)
    const now = new Date().getTime()

    if (item.expires && now > item.expires) {
      localStorage.removeItem(key)
      console.log(`localstorage key ${key} been removed due to expiry. current time: ${now}. expires in: ${item.expires}`)
  return null
    }

    return item.value
  } catch {
    return null
  }
}

export function deleteByKey(key) {
  if (!key) {
    return
  }

  localStorage.removeItem(key)
  console.log(`localstorage key ${key} been removed.`)
}
