import axios from 'axios'
import store from '@/store'
import { Notify } from 'vant'

const client = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
})

client.interceptors.request.use(function (config) {
  const token = store.getters['token']
  if (token) {
    config.headers.token = token
  }

  return config
})

client.interceptors.response.use(
  function (res) {
    // 如果 code 不为0，视为业务错误
    if (res.data.code !== 0) {
      if (res.data.code == 401) {
        Notify({ type: 'danger', message: '登录信息已过期，请刷新页面重新登录' })
        store.dispatch('user/logout')
      } else {
        Notify({ type: 'danger', message: res.data.msg })
      }
      return Promise.reject(res.data)
    }
    return res.data
  },
  function (error) {
    if (error.message.includes('Network')) {
      // 处理网络错误
      Notify({ type: 'danger', message: '啊哦，网络链接不稳定哦~' })
    } else if (error.response && error.response.status >= 500) {
      // 处理服务器错误
      Notify({ type: 'danger', message: '服务暂不可用，请稍后重试' })
    } else {
      // 其他错误
      Notify({ type: 'danger', message: error.message })
    }

    return Promise.reject(error)
  }
)

async function get(path, queryParams) {
  let result = await client.get(path, {
    params: queryParams,
  })

  return result.data
}

async function post(path, bodyParams, header) {
  let result = await client.post(path, bodyParams, header)
  return result.data
}

// function processError() {}

export default {
  get,
  post,
}
