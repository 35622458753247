import router from './index'
import store from '@/store/index'

router.beforeEach(async (to, from, next) => {
  setPageTitle(to)

  let isAuthenticated = store.getters['isAuthenticated']

  if (!checkMenuExists(to)) {
    // redirect to 404
    console.warn('redirect to error 404')
    next({
      name: 'error',
      query: { title: '404', detail: '您访问的页面不存在' }
    })

    return
  }

  // authentication bypass
  if (checkForBypassAuthenticationRoutes(to)) {
    next()
    return
  }

  // for unauthenticated requests, redirect to login
  if (!isAuthenticated) {
    console.log('unauthenticated request. redirect to login...')
    loginExpired(to, next)
    return
  }

  // finally let the visit pass
  next()
})

function setPageTitle(to) {
  let title = null
  if (to && to.meta && to.meta.title) {
    title = to.meta.title
  } else {
    title = 'BOOM直播'
  }

  document.title = title
}

function checkForBypassAuthenticationRoutes(to) {
  let shouldBypass = false;

  for (let i = 0; i < to.matched.length; i++) {
    let item = to.matched[i];

    if (item.meta === undefined) {
      continue;
    }

    switch (item.meta.allowAnoymous) {
      case undefined:
        continue;
      case false:
        break;
      case true:
        shouldBypass = true;
        break;
    }
  }

  return shouldBypass;
}

function loginExpired(to, next) {
  redirectToLogin(to, next);
}

function redirectToLogin(to, next) {
  console.log(to)
  next({
    name: "authLogin",
    query: { redirect: to.fullPath },
    replace: true
  });
  return;
}

function checkMenuExists(to) {
  if (!to.matched || to.matched.length == 0) {
    return false;
  }

  return true;
}