import { retrieveByKey, setByKey, deleteByKey } from '../utils/persistentUtils'

const getDefaultState = () => {
  return {
    token: retrieveByKey('token'),
    // userInfo: getUser(),
    loginRedirect: '',
  }
}

const state = getDefaultState()

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_USERINFO: (state, userInfo) => {
    state.userInfo = userInfo
  },
  SET_LOGIN_REDIRECT: (state, redirect) => {
    state.loginRedirect = redirect
  },
}

const actions = {
  // setUserInfo({ commit }, data) {
  //   setToken(data.token.tokenValue)
  //   commit('SET_TOKEN', data.token.tokenValue)

  //   setUser(data.userInfo)
  //   commit('SET_USERINFO', data.userInfo)
  // },
  setToken({ commit }, token) {
    setByKey('token', token, 7 * 24 * 60 * 60)
    commit('SET_TOKEN', token)
  },
  setLoginRedirect({ commit }, redirect) {
    setByKey('redirect', redirect)
    commit('SET_LOGIN_REDIRECT', redirect)
  },
  getLoginRedirect({ commit }) {
    const redirect = retrieveByKey('redirect')
    if (redirect) {
      commit('SET_LOGIN_REDIRECT', '')
      deleteByKey('redirect')
    }

    return redirect
  },
  // user logout
  logout({ commit }) {
    return new Promise((resolve) => {
      deleteByKey('token') // must remove  token  first
      // removeUser()
      commit('RESET_STATE')
      sessionStorage.clear()
      resolve()
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
