<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import toolsApi from "@/services/apis/tools-api";

export default {
  created () {
    /* global wx */
    this.initWxConfig()

  },
  methods: {
    async initWxConfig () {
      const ticketResult = await toolsApi.getWxTicket()
      if (!ticketResult) {
        return
      }

      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: process.env.VUE_APP_OA_APP_ID, // 必填，公众号的唯一标识
        timestamp: ticketResult.timestamp, // 必填，生成签名的时间戳
        nonceStr: ticketResult.nonceStr, // 必填，生成签名的随机串
        signature: ticketResult.signature,// 必填，签名
        jsApiList: ['openProfile'] // 必填，需要使用的JS接口列表
      });

      wx.ready(function () {
        console.log('wx ready')
        wx.hideAllNonBaseMenuItem()
      })
    },
  }
}
</script>

<style>
</style>
