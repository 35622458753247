import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/livetask/application',
    name: 'liveTaskApplication',
    meta: {
      title: '加入直播任务',
      allowAnoymous: false
    },
    component: () => import('../views/LiveTask/LiveTaskApplication.vue')
  },
  {
    path: '/livetask/applicationlist',
    name: 'liveTaskApplicationList',
    meta: {
      title: '任务申请记录',
      allowAnoymous: false
    },
    component: () => import('../views/LiveTask/LiveTaskApplicationList.vue')
  },
  {
    path: '/livetask/applicationsuccess',
    name: 'liveTaskApplicationSuccess',
    meta: {
      title: '提交成功',
      allowAnoymous: false
    },
    component: () => import('../views/LiveTask/LiveTaskApplicationSuccess.vue')
  },
  {
    path: '/error',
    name: 'error',
    meta: {
      title: '错误',
      allowAnoymous: true
    },
    component: () => import('../views/ErrorView.vue')
  },
  {
    path: '/auth/login',
    name: 'authLogin',
    meta: {
      title: '登录中...',
      allowAnoymous: true
    },
    component: () => import('../views/Auth/LoginView.vue')
  },
  {
    path: '/auth/redirect',
    name: 'authRedirect',
    meta: {
      title: '跳转中...',
      allowAnoymous: true
    },
    component: () => import('../views/Auth/RedirectView.vue')
  },
  {
    path: '/smsToApplet/smsToApplet',
    name: 'smsToApplet',
    meta: {
      title: '跳转中...',
      allowAnoymous: true
    },
    component: () => import('../views/SmsToApplet/SmsToApplet.vue')
  },
  {
    path:'/admin/admin',
    name:'admin',
    meta:{
      title:"账号绑定",
      allowAnoymous: false
    },
    component:()=>import('../views/admin/admin.vue')
  },
  {
    path:'/admin/editUserInfo',
    name:'EditUserInfo',
    meta:{
      title:"账号绑定",
      allowAnoymous: false
    },
    component:()=>import('../views/admin/editUserInfo.vue')
  },
  {
    path:'admin/success',
    name:'adminsuccess',
    meta:{
      title:"绑定成功",
      allowAnoymous:false,
    },
    component:()=>import('../views/admin/success.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
