import axios from 'axios'
import client from './http-client'

function getWxTicket() {
  return client.get('/client/cas/ticket')
}

function hasSubscribedOa() {
  return client.get('/client/cas/hasSubscribe')
}

function getAppletShortLink(data) {
	return client.post('/client/cas/generateMbbUrlLink', data)
}

function bindWexin(id){
  return client.get(`/client/ums/user/account/bindWeChat/${id}`)
}

function getQiniuToken(params) {
  return client.get('/admin/common/getQiNiuToken', params)
}
function upload(data) {
  const axios1 = axios.create();
  return axios1.post(process.env.VUE_APP_QINIU_URL, data, {headers: {'Content-Types': 'multipart/form-data'}})
}

export default {
  getWxTicket,
  hasSubscribedOa,
  getAppletShortLink,
  bindWexin,
  getQiniuToken,
  upload
}